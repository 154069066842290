export const initialExposedState = {
  id: null,
  submit: () => {},
  isValid: true,
  isSubmitted: false,
  values: {},
  invalidateFields: () => {},
  setFieldsValues: () => {},
  reset: () => {},
  resetKey: 0,
  currentStep: {},
  steps: [],
  isStepValid: true,
  isStepSubmitted: false,
  isFirstStep: true,
  isLastStep: true,
  submitStep: () => {},
  getFieldStepName: () => {},
  nextStep: () => {},
  prevStep: () => {},
  goToStep: () => {},
};
